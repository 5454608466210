import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { Grid, Cell } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { ButtonGroup } from 'baseui/button-group'
import { Input, SIZE as InputSize } from 'baseui/input'
import { Search } from 'baseui/icon'

import { ERROR_MESSAGE } from '../../constants'
import SubmitButton from '../../common/generic/SubmitButton'

const SearchReturnPOFormBody = () => {
  const {
    formState: { isSubmitting, errors },
  } = useFormContext()

  return (
    <>
      <Grid>
        <Cell span={4}>
          <FormControl
            label="Return PO Number"
            error={errors.return_id?.message || null}
          >
            <ButtonGroup>
              <Controller
                name="return_id"
                rules={{ required: ERROR_MESSAGE.required }}
                render={({ field }) => (
                  <Input
                    {...field}
                    startEnhancer={<Search size="26px" />}
                    placeholder="Search for return PO Number..."
                    size={InputSize.large}
                  />
                )}
              />
              <SubmitButton isLoading={isSubmitting}>Search</SubmitButton>
            </ButtonGroup>
          </FormControl>
        </Cell>
      </Grid>
    </>
  )
}

export default SearchReturnPOFormBody
