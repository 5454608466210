import * as React from 'react';
import { useAuth } from '../../context/auth-context';
import {
  ALIGN,
  HeaderNavigation,
  StyledNavigationItem as NavigationItem,
  StyledNavigationList as NavigationList,
} from 'baseui/header-navigation';
import { Link } from 'react-router-dom';
import { Button } from 'baseui/button';
import { ChevronDown } from 'baseui/icon';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';
import { Avatar } from 'baseui/avatar';
import { expandBorderStyles } from 'baseui/styles';

import style from './Navbar.module.css';
import brandmark from '../../assets/img/carewell-brandmark.svg';
import { ROUTE } from '../../routes';

const Navbar = () => {
  const { user, signIn, signOut } = useAuth();
  const autoshipMenuItems: any = [];

  if (process.env.REACT_APP_RETOOL_AUTOSHIPS_BY_SKU) {
    autoshipMenuItems.push({ label: 'Lookup by Sku', href: ROUTE.autoshipLookup });
  }
  if (process.env.REACT_APP_RETOOL_AUTOSHIPS_BY_EMAIL) {
    autoshipMenuItems.push({ label: 'Lookup by Email', href: ROUTE.autoshipEmailLookup });
  }
  if (process.env.REACT_APP_RETOOL_MANAGE_AUTOSHIP) {
    autoshipMenuItems.push({ label: 'Manage Autoship', href: ROUTE.manageAutoship });
  }

  return (
    <HeaderNavigation>
      <NavigationList $align={ALIGN.left}>
        <NavigationItem>
          <img className={style.logoIcon} src={brandmark} alt="" />
          <Link style={{ fontSize: '18px' }} to={ROUTE.root}>
            Carewell Admin
          </Link>
        </NavigationItem>
      </NavigationList>
      <NavigationList $align={ALIGN.center} />

      <NavigationList $align={ALIGN.right}>
        <NavigationItem>
          <Link to={ROUTE.returnAuthorization}>Return Authorization</Link>
        </NavigationItem>

        {user ? (
          <>
            <NavigationItem>
              <Link to={ROUTE.returnRequest}>Return Request</Link>
            </NavigationItem>
            {/* TODO Deprecate this feature:
            <NavigationItem>
              <Link to={ROUTE.returns}>Returns (Legacy)</Link>
            </NavigationItem> */}
            {/* <NavigationItem>
              <Link to={ROUTE.notifications}>Notifications</Link>
            </NavigationItem> */}
            <NavigationItem>
              <Link to={ROUTE.letters}>Snail Mail</Link>
            </NavigationItem>
            {autoshipMenuItems.length !== 0 && (
              <NavigationItem>
                <StatefulPopover
                  focusLock
                  placement={PLACEMENT.bottomRight}
                  content={() => (
                    <StatefulMenu
                      items={autoshipMenuItems}
                      overrides={{
                        Option: {
                          props: {
                            overrides: {
                              ListItemAnchor: {
                                style: () => ({
                                  textDecoration: 'none',
                                }),
                              },
                            },
                          },
                        },
                      }}
                    />
                  )}
                >
                  <Button
                    overrides={{
                      BaseButton: {
                        style: () => ({
                          paddingTop: '8px',
                          paddingBottom: '8px',
                        }),
                      },
                    }}
                    endEnhancer={() => <ChevronDown size={24} />}
                  >
                    Autoships
                  </Button>
                </StatefulPopover>
              </NavigationItem>
            )}
            <NavigationItem>
              <StatefulPopover
                focusLock
                placement={PLACEMENT.bottomRight}
                content={() => (
                  <StatefulMenu
                    items={[{ label: `Signed in as ${user?.name}`, disabled: true }, { label: 'Logout' }]}
                    onItemSelect={signOut}
                  />
                )}
              >
                <Button
                  overrides={{
                    BaseButton: {
                      style: () => ({
                        paddingTop: '8px',
                        paddingBottom: '8px',
                      }),
                    },
                  }}
                  endEnhancer={() => <ChevronDown size={24} />}
                >
                  <Avatar
                    name={user?.name}
                    size="scale900"
                    src={user?.picture}
                    overrides={{
                      Root: {
                        style: ({ $theme }) => ({
                          ...expandBorderStyles($theme.borders.border200),
                        }),
                      },
                      Initials: {
                        style: ({ $theme }) => ({
                          backgroundColor: $theme.colors.accent100,
                          borderRadius: '50%',
                        }),
                      },
                    }}
                  />
                </Button>
              </StatefulPopover>
            </NavigationItem>
          </>
        ) : (
          <NavigationItem>
            <Button onClick={signIn}>Sign in with Google</Button>
          </NavigationItem>
        )}
      </NavigationList>
    </HeaderNavigation>
  );
};

export default Navbar;
