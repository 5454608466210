import * as React from 'react';
import { Block } from 'baseui/block';
import { Button, KIND as ButtonKind, SIZE as ButtonSize, SHAPE as ButtonShape } from 'baseui/button';
import { ChevronDown } from 'baseui/icon';
import { Combobox } from 'baseui/combobox';
import { FileUploader } from 'baseui/file-uploader';
import { FormControl } from 'baseui/form-control';
import { Grid, Cell } from 'baseui/layout-grid';
import { Input } from 'baseui/input';
import { Notification, KIND as NotificationKind } from 'baseui/notification';
import { Select } from 'baseui/select';
import { Delete } from 'baseui/icon'
import { OptionT } from '../../../types';
import { useLetters, defaultData, defaultFormData, ErrorsT } from '../../../context/letters-context';
import { STATES, ADDRESS_TYPES } from '../../../constants';
import CancelConfirmation from '../../../common/modal/CancelConfirmation';
import { useAuth } from '../../../context/auth-context';

type SendMailProps = {
  onCancel: () => void;
  onError: (message?: string) => void;
  onSubmit: () => void;
};

const SendMail = (props: SendMailProps) => {
  const { checkAuth } = useAuth();
  const { data, setData, formData, setFormData, errors, setErrors } = useLetters();
  const [modalOpen, setModalOpen] = React.useState(false);
  const fieldSetRef = React.useRef<HTMLFieldSetElement | null>(null);

  React.useEffect(
    () => {
      if (formData?.orderId) {
        // TODO: this logic is used in both CustomerLookup and SendMail
        const addressObj = formData.addressType?.id === 'billing' ? data?.billingAddress : data?.shippingAddress;
        const state = STATES.find(state => state.id === addressObj?.state_or_province) || STATES[0];

        setFormData({
          ...formData,
          firstName: addressObj?.first_name || '',
          lastName: addressObj?.last_name || '',
          addressLine1: addressObj?.address1 || '',
          addressLine2: addressObj?.address2 || '',
          city: addressObj?.city || '',
          state: [state],
          zip: addressObj?.postal_code || '',
        });
      }
    },
    // TODO: resolve react-hooks/exhaustive-deps warning
    // eslint-disable-next-line
    [formData?.orderId, formData?.addressType]
  );

  const handleCancel = () => {
    handleReset();
    props.onCancel();
  };

  const handleReset = () => {
    handleDisabled(false);
    setData(defaultData);
    setFormData(defaultFormData);
    setErrors({});
    setModalOpen(false);
  }

  const handleDisabled = (input: boolean) => {
    if (fieldSetRef.current) {
      fieldSetRef.current.disabled = input
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const formErrors: ErrorsT = {};

    // TODO: abstract this required validation via props...

    if (!formData.firstName) {
      formErrors.firstName = 'Required';
    }

    if (!formData.lastName) {
      formErrors.lastName = 'Required';
    }

    if (!formData.addressLine1) {
      formErrors.addressLine1 = 'Required';
    }

    if (!formData.city) {
      formErrors.city = 'Required';
    }

    if (!formData.state) {
      formErrors.state = 'Required';
    }

    if (!formData.zip) {
      formErrors.zip = 'Required';
    }

    if (!formData.files) {
      formErrors.file = 'Required';
    }

    if (Object.keys(formErrors as object).length < 1) {
      handleDisabled(true);
      const formDataObj = new FormData();

      formDataObj.append('first_name', formData.firstName);
      formDataObj.append('last_name', formData.lastName);
      formDataObj.append('email', formData?.emailAddress || '');
      formDataObj.append('address_line1', formData.addressLine1);
      formDataObj.append('address_line2', formData?.addressLine2 || '');
      formDataObj.append('address_city', formData.city);
      formDataObj.append('address_state', (formData.state?.[0]?.id as string) || '');
      formDataObj.append('address_zip', formData.zip);

      for (const file of formData.files ?? []) {
        formDataObj.append('files', file as Blob);
      }

      const user = checkAuth && (await checkAuth());

      try {
        const response = await fetch(`${process.env.REACT_APP_CW_API_ADMIN_URL}/notifications/mail/send`, {
          method: 'POST',
          mode: 'cors',
          headers: { authorization: `Bearer ${user?.auth.idToken}` },
          body: formDataObj,
        });

        if (response.status >= 200 && response.status <= 299) {
          handleReset();
          props.onSubmit();
        } else {
          const err = await response.json()
          console.error('error submitting mail', { err });
          handleDisabled(false);
          props.onError(err?.message);
          
        }
      } catch (err) {
        console.error('error submitting mail', { err });
        handleDisabled(false);
        props.onError((err as any)?.message);
      }
    } else {
      handleDisabled(false);
      setErrors({ ...formErrors });
    }
  };

  return (
    <>
      <form id="snail-mail-form" method="POST" onSubmit={handleSubmit}>
        <fieldset ref={fieldSetRef}>
        <Grid>
          {errors.server ? (
            <Cell span={12}>
              <Notification
                kind={NotificationKind.negative}
                overrides={{
                  Body: { style: { width: 'auto' } },
                }}
              >
                {errors.server}
              </Notification>
            </Cell>
          ) : null}

          <Cell span={4}>
            <FormControl label="First Name" error={errors.firstName || null}>
              <Input
                value={formData.firstName}
                onChange={event =>
                  setFormData({
                    ...formData,
                    firstName: event.currentTarget.value,
                  })
                }
                error={!!errors.firstName}
              />
            </FormControl>
          </Cell>

          <Cell span={4}>
            <FormControl label="Last Name" error={errors.lastName || null}>
              <Input
                value={formData.lastName}
                onChange={event =>
                  setFormData({
                    ...formData,
                    lastName: event.currentTarget.value,
                  })
                }
                error={!!errors.lastName}
              />
            </FormControl>
          </Cell>

          <Cell span={4}>
            <FormControl label="Email Address" caption="Optional">
              <Input
                type="email"
                value={formData.emailAddress}
                onChange={event =>
                  setFormData({
                    ...formData,
                    emailAddress: event.currentTarget.value,
                  })
                }
              />
            </FormControl>
          </Cell>

          {data?.billingAddress ? (
            <Cell span={4}>
              <FormControl label="Address Type" caption="Selection will autofill customer name and address">
                <Combobox
                  value={formData.addressType?.label || ''}
                  onChange={(_, option) => setFormData({ ...formData, addressType: option as OptionT })}
                  mapOptionToString={option => option.label}
                  options={ADDRESS_TYPES}
                  overrides={{
                    Input: {
                      props: {
                        endEnhancer: <ChevronDown size="26px" />,
                      },
                    },
                  }}
                />
              </FormControl>
            </Cell>
          ) : null}

          <Cell span={8}></Cell>

          <Cell span={6}>
            <FormControl label="Address Line 1" error={errors.addressLine1 || null}>
              <Input
                value={formData.addressLine1}
                onChange={event =>
                  setFormData({
                    ...formData,
                    addressLine1: event.currentTarget.value,
                  })
                }
                error={!!errors.addressLine1}
              />
            </FormControl>
          </Cell>

          <Cell span={6}>
            <FormControl label="Address Line 2" caption="Optional">
              <Input
                value={formData.addressLine2}
                onChange={event =>
                  setFormData({
                    ...formData,
                    addressLine2: event.currentTarget.value,
                  })
                }
              />
            </FormControl>
          </Cell>

          <Cell span={6}>
            <FormControl label="City" error={errors.city || null}>
              <Input
                value={formData.city}
                onChange={event => setFormData({ ...formData, city: event.currentTarget.value })}
                error={!!errors.city}
              />
            </FormControl>
          </Cell>

          <Cell span={3}>
            <FormControl label="State" error={errors.state || null}>
              <Select
                labelKey="label"
                valueKey="id"
                value={formData.state || []}
                onChange={({ value }) => setFormData({ ...formData, state: value })}
                options={STATES}
                maxDropdownHeight="260px"
                clearable={false}
              />
            </FormControl>
          </Cell>

          <Cell span={3}>
            <FormControl label="Zip Code" error={errors.zip || null}>
              <Input
                value={formData.zip}
                onChange={event => setFormData({ ...formData, zip: event.currentTarget.value })}
                error={!!errors.zip}
              />
            </FormControl>
          </Cell>

          <Cell span={12} overrides={{ Cell: { style: { marginTop: '24px' } } }}>
            <FormControl error={errors.file || null}>
              <FileUploader
                accept=".pdf"
                multiple={true}
                onDrop={acceptedFiles => {
                  console.log(acceptedFiles)
                  setFormData({ ...formData, files: acceptedFiles });
                }}
              />
            </FormControl>
            {formData?.files ? (
              formData?.files.map((file, index) => (
                <Notification
                key={`${file.name}-${Date.now()}`}
                kind={NotificationKind.positive}
                overrides={{
                  Body: { style: { width: 'auto' } },
                }}
              >
                {({dismiss}) => (
                  <div style={{ display: 'contents' }}>
                    <p>{file.name}</p>
                    <Button onClick={() => {
                      handleDisabled(true);
                      if (formData?.files) {
                        formData.files.splice(index, 1)
                        if (formData.files.length > 0) {
                          setFormData({ ...formData, files: formData.files })
                        } else {
                          setFormData({ ...formData, files: undefined })
                        }
                      }
                      dismiss();
                      handleDisabled(false);
                    }} size={ButtonSize.mini} shape={ButtonShape.circle}>
                      <Delete />
                    </Button>
                  </div>
                )}
              </Notification>
              ))
            ) : null}
          </Cell>

          {/* Spacer */}
          <Block height="24px" width="100%">
            &nbsp;
          </Block>

          <Cell span={1}>
            <Button>Submit</Button>
          </Cell>

          <Cell span={1}>
            <Button type="button" kind={ButtonKind.secondary} onClick={() => setModalOpen(true)}>
              Cancel
            </Button>
          </Cell>
        </Grid>
        </fieldset>
      </form>

      <CancelConfirmation open={modalOpen} setOpen={setModalOpen} onSubmit={handleCancel} />
    </>
  );
};

export default SendMail;
