import React from 'react'
import { Grid, Cell } from 'baseui/layout-grid'
import { Notification, KIND as NotificationKind } from 'baseui/notification'

const ReturnAuthorizationCanceledAlert = () => {
  return (
    <>
      <Grid>
        <Cell span={12}>
          <Notification
            kind={NotificationKind.info}
            overrides={{
              Body: { style: { width: 'auto' } },
              InnerContainer: {
                style: {
                  width: '100%',
                },
              },
            }}
          >
            <span>This Return Request was Cancelled by Carewell.</span>
          </Notification>
        </Cell>
      </Grid>
    </>
  )
}

export default ReturnAuthorizationCanceledAlert
