export const ROUTE = {
  root: '/',
  // returns: '/returns',
  returnRequest: '/returns/request',
  returnAuthorization: '/returns/authorization',
  notifications: '/notifications',
  letters: '/snail-mail',
  autoshipLookup: '/autoships/lookup',
  autoshipEmailLookup: '/autoships/lookupemail',
  manageAutoship: '/autoships/manage',
}
