import React from 'react'
import { Grid, Cell } from 'baseui/layout-grid'
import { Notification, KIND as NotificationKind } from 'baseui/notification'

const ReturnAuthorizationConfirmation = ({
  returnId,
}: {
  returnId: string
}) => {
  return (
    <>
      <Grid>
        <Cell span={12}>
          <Notification
            kind={NotificationKind.positive}
            overrides={{
              Body: { style: { width: 'auto' } },
              InnerContainer: {
                style: {
                  width: '100%',
                },
              },
            }}
          >
            <span>
              Success! We’ve received your return authorization for purchase
              order number: {returnId}
            </span>
          </Notification>
        </Cell>
      </Grid>
    </>
  )
}

export default ReturnAuthorizationConfirmation
