import * as React from 'react';
import { useAuth } from '../context/auth-context';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';

import { THEME, getTheme } from '../theme';
import { ROUTE } from '../routes';
import style from './App.module.css';

import Navbar from '../common/layout/Navbar';
import Footer from '../common/layout/Footer';
import Loading from './Loading';
import Error from './Error';
import Landing from './Landing';
// import ReturnsManager from '../modules/return-request/ReturnsManager'
import ReturnRequest from '../modules/return-request/ReturnRequest';
import ReturnAuthorization from '../modules/return-authorization/ReturnAuthorization';
import Notifications from '../modules/notifications/Notifications';
import SnailMail from '../modules/snail-mail/SnailMail';
import AutoshipLookupBySku from '../modules/autoships/AutoshipLookupBySku';
import AutoshipLookupByEmail from '../modules/autoships/AutoshipLookupByEmail';
import ManageAutoship from '../modules/autoships/ManageAutoship';

const engine = new Styletron();

const App = () => {
  const [theme] = React.useState(THEME.light); // TODO: default to user preference
  const { user, status, error } = useAuth();

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={getTheme(theme)}>
        <Router>
          <div className={style.layout}>
            <header className={style.header}>
              <Navbar />
            </header>
            <main className={style.main}>
              {status === 'loading' ? <Loading /> : null}

              {status === 'error' && error ? <Error message={error.message} /> : null}

              <Switch>
                <Route exact path={ROUTE.root}>
                  <Landing />
                </Route>
                <Route path={ROUTE.returnAuthorization}>
                  <ReturnAuthorization />
                </Route>

                {(status === 'success' || !status) && user ? (
                  <>
                    <Route path={ROUTE.returnRequest}>
                      <ReturnRequest />
                    </Route>
                    {/* <Route exact path={ROUTE.returns}>
                      <ReturnsManager />
                    </Route> */}
                    <Route path={ROUTE.notifications}>
                      <Notifications />
                    </Route>
                    <Route path={ROUTE.letters}>
                      <SnailMail />
                    </Route>
                    <Route path={ROUTE.autoshipLookup}>
                      <AutoshipLookupBySku />
                    </Route>
                    <Route path={ROUTE.autoshipEmailLookup}>
                      <AutoshipLookupByEmail />
                    </Route>
                    <Route path={ROUTE.manageAutoship}>
                      <ManageAutoship />
                    </Route>
                  </>
                ) : null}
              </Switch>
            </main>

            <footer className={style.footer}>
              <Footer />
            </footer>
          </div>
        </Router>
      </BaseProvider>
    </StyletronProvider>
  );
};

export default App;
