import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { ERROR_MESSAGE } from '../../constants';
import { parseJson } from '../../utils';
import SearchOrderFormBody from './SearchOrderFormBody';
import type { OrderDataT } from './types';

const defaultValues = {
  order_number: '',
};

const SearchOrderForm = ({
  returnOrderNumber,
  onSearchSubmit,
}: {
  returnOrderNumber: string;
  onSearchSubmit: (data: OrderDataT) => void;
}) => {
  const methods = useForm({ defaultValues });

  React.useEffect(() => {
    if (returnOrderNumber) {
      methods.reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnOrderNumber]);

  const handleSearchSubmit = async (data: any) => {
    try {
      const resp = await fetch(`${process.env.REACT_APP_CW_API_ADMIN_URL}/returns/request/${data.order_number}`);
      const json = await parseJson(resp);

      if (!resp.ok) {
        throw new Error(json?.msg);
      }

      onSearchSubmit({
        ...json,
        order_number: data.order_number,
      });
    } catch (error: any) {
      methods.setError('order_number', {
        message: error.message || ERROR_MESSAGE.generic,
      });
      return false;
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSearchSubmit)}>
        <SearchOrderFormBody />
      </form>
    </FormProvider>
  );
};

export default SearchOrderForm;
