import * as React from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'

import cxIcon from '../assets/img/icon-cx.svg'

const Landing = () => (
  <FlexGrid height='calc(100vh - 370px)' alignItems='center' justifyContent='center'>
    <FlexGridItem
      overrides={{
        Block: {
          style: () => ({
            flexGrow: 0,
            textAlign: 'center',
            width: 'auto',
          }),
        },
      }}
    >
      <img src={cxIcon} alt='' style={{ height: '200px' }} />
    </FlexGridItem>
  </FlexGrid>
)

export default Landing
