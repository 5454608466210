import { withStyle } from 'baseui'
import { StyledSpinnerNext as Spinner } from 'baseui/spinner'

const CustomSpinner = withStyle(
  Spinner,
  ({ $theme, size }: { $theme: any; size: 'small' | 'large' }) =>
    size === 'small'
      ? {
          width: '18px',
          height: '18px',
          borderLeftWidth: '4px',
          borderRightWidth: '4px',
          borderTopWidth: '4px',
          borderBottomWidth: '4px',
          borderTopColor: $theme.colors.accent100,
        }
      : {
          width: '60px',
          height: '60px',
          borderLeftWidth: '8px',
          borderRightWidth: '8px',
          borderTopWidth: '8px',
          borderBottomWidth: '8px',
          borderTopColor: $theme.colors.accent100,
        }
)

export default CustomSpinner
