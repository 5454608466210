import { useFormContext } from 'react-hook-form';
import { Notification, KIND as NotificationKind } from 'baseui/notification';
import ReturnLabelNumber from './ReturnLabelNumber';
import type { ReturnLabelT } from '../types';
import { Button, SIZE } from 'baseui/button';

const ReturnLabel = ({
  file,
  onRemoveFile,
  onRemoveNumber,
  addNumber,
  modifyNumber,
}: {
  file: ReturnLabelT;
  onRemoveFile: (file: ReturnLabelT) => void;
  onRemoveNumber: (file: ReturnLabelT, number: string) => void;
  addNumber: (file: ReturnLabelT) => void;
  modifyNumber: (file: ReturnLabelT, originalNumber: string, number: string) => void;
}) => {
  return (
    <Notification
      kind={NotificationKind.positive}
      overrides={{
        Body: { style: { width: 'auto' } },
      }}
      onClose={() => onRemoveFile(file)}
      closeable
    >
      {file.file}
      {file.tracking.length ? (
        file.tracking.map(trk => (
          <ReturnLabelNumber
            key={trk.number}
            file={file}
            tracking={trk}
            onRemoveNumber={onRemoveNumber}
            modifyNumber={modifyNumber}
          />
        ))
      ) : (
        <p style={{ color: '#AB1300' }}>No tracking number could be extracted, please update here</p>
      )}
      <Button onClick={() => addNumber(file)} size={SIZE.mini}>
        + Add Tracking Number
      </Button>
    </Notification>
  );
};

const ReturnLabels: Function = () => {
  const { watch, setValue } = useFormContext();

  const watchReturnLabel = watch('return_label') as ReturnLabelT[];
  const filterFile = (file: ReturnLabelT) => watchReturnLabel.filter((f: ReturnLabelT) => f.file !== file.file);

  const onRemoveFile = (file: ReturnLabelT) => {
    setValue('return_label', filterFile(file));
  };

  const onRemoveNumber = (file: ReturnLabelT, number: string) => {
    const label = watchReturnLabel.find(l => l.file === file.file);
    if (label) {
      label.tracking = label.tracking.filter(trk => trk.number !== number);
      setValue('return_label', label.tracking.length ? watchReturnLabel : filterFile(file));
    }
  };

  const addNumber = (file: ReturnLabelT) => {
    const label = watchReturnLabel.find(l => l.file === file.file);
    if (label) {
      label.tracking.push({
        id: '',
        carrier: '',
        number: '',
        status: '',
        error: true,
      });
      setValue('return_label', watchReturnLabel);
    }
  };

  const modifyNumber = (file: ReturnLabelT, originalNumber: string, number: string) => {
    const label = watchReturnLabel.find(l => l.file === file.file);
    if (label) {
      label.tracking = label.tracking.map(trk => {
        if (trk.number === originalNumber) {
          trk.number = number;
        }
        return trk;
      });
      setValue('return_label', watchReturnLabel);
    }
  };

  return watchReturnLabel
    ? watchReturnLabel.map(file => (
        <ReturnLabel
          key={file.file}
          file={file}
          onRemoveFile={onRemoveFile}
          onRemoveNumber={onRemoveNumber}
          addNumber={addNumber}
          modifyNumber={modifyNumber}
        />
      ))
    : null;
};

export default ReturnLabels;
