import React from 'react'
import { Grid, Cell } from 'baseui/layout-grid'
import { H1 } from 'baseui/typography'
import Retool from 'react-retool'
import style from './Autoship.module.css'

const ManageAutoship = () => {
  return (
    <>
      <Grid>
        <Cell span={12}>
          <H1>Manage Autoship</H1>
          <div className={style.embed}>
            <Retool url={process.env.REACT_APP_RETOOL_MANAGE_AUTOSHIP} />
          </div>
        </Cell>
      </Grid>
    </>
  )
}

export default ManageAutoship
