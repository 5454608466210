import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Input } from 'baseui/input';
import { FormControl } from 'baseui/form-control';
import { ERROR_MESSAGE } from '../../../constants';

const minQuantity = 0;

const ReturnQuantityInput = ({
  namespace,
  index,
  maxQuantity,
}: {
  namespace: string;
  index: number;
  maxQuantity: number;
}) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const hasReturnableQuantity = maxQuantity > 0;

  const INPUT_NAME = `${namespace}.${index}.return_quantity`;

  const watchReturnFull = watch('return_full');

  React.useEffect(() => {
    if (watchReturnFull === true) {
      setValue(INPUT_NAME, maxQuantity);
    }
  }, [watchReturnFull, maxQuantity, setValue, INPUT_NAME]);

  return (
    <>
      <FormControl error={errors[namespace]?.[index]?.return_quantity?.message || null}>
        <Controller
          name={INPUT_NAME}
          rules={
            hasReturnableQuantity
              ? {
                  required: ERROR_MESSAGE.required,
                  min: {
                    value: minQuantity,
                    message: ERROR_MESSAGE.min(minQuantity),
                  },
                  max: {
                    value: maxQuantity,
                    message: ERROR_MESSAGE.max(maxQuantity),
                  },
                }
              : undefined
          }
          render={({ field: { ref, ...fieldProps } }) => (
            <Input
              {...fieldProps}
              type="number"
              disabled={!hasReturnableQuantity}
              onChange={event => {
                setValue('return_full', false);
                fieldProps.onChange(event);
              }}
              endEnhancer={`/ ${hasReturnableQuantity ? maxQuantity : 0}`}
              overrides={{
                Input: {
                  style: { paddingRight: 0 },
                },
                EndEnhancer: {
                  style: ({ $theme }) => ({
                    whiteSpace: 'nowrap',
                    color: $theme.colors.mono700,
                  }),
                },
              }}
            />
          )}
        />
      </FormControl>
    </>
  );
};

export default ReturnQuantityInput;
