import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { ERROR_MESSAGE } from '../../../constants';
import { parseJson } from '../../../utils';
import ReturnAuthorizationError from '../ReturnAuthorizationError';
import type { ReturnDataT } from '../types';
import ReturnAuthorizationFormBody from './ReturnAuthorizationFormBody';

const ReturnAuthorizationForm = ({
  returnData,
  onReturnSubmit,
}: {
  returnData: ReturnDataT;
  onReturnSubmit: (data: any) => void;
}) => {
  const defaultValues = {
    global: null,
    return_label: [],
    rma_number: '',
    line_item: returnData.line_items.map(() => ({
      authorization_status: [],
      authorization_action: [],
    })),
  };

  const methods = useForm({
    mode: 'onBlur',
    shouldUnregister: true,
    defaultValues,
  });

  const [returnId, setReturnId] = React.useState(returnData.return_id);

  React.useEffect(() => {
    if (methods.formState.isValidating) {
      methods.clearErrors('global');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState]);

  React.useEffect(() => {
    if (returnData.return_id !== returnId || methods.formState.isSubmitSuccessful) {
      methods.reset(defaultValues);
      setReturnId(returnData.return_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnData.return_id, methods.formState, returnId]);

  const handleReturnSubmit = async (data: any) => {
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_CW_API_ADMIN_URL}/returns/authorization/${returnData.return_id}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            vendorCSR: data.vendor_csr,
            raNumber: data.rma_number,
            returnLabels: data.return_label,
            lineItems: Object.entries(data.line_item).map(([key, value]: any[]) => {
              const origValue = returnData.line_items[key];
              return {
                recordId: origValue?.record_id,
                status: value.authorization_status?.[0]?.id,
                action: value.authorization_action?.[0]?.id,
              };
            }),
          }),
        }
      );

      const json = await parseJson(resp);

      if (!resp.ok) {
        throw new Error(json?.msg);
      }

      onReturnSubmit(json);
    } catch (error: any) {
      methods.setError('global', {
        message: error.message || ERROR_MESSAGE.generic,
      });
      return false;
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleReturnSubmit)}>
          <ReturnAuthorizationError errorMessage={methods.formState.errors.global?.message} />
          <ReturnAuthorizationFormBody data={returnData} />
        </form>
      </FormProvider>
    </>
  );
};

export default ReturnAuthorizationForm;
