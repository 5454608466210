import * as React from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import CustomSpinner from '../common/generic/CustomSpinner'

const Loading = () => (
  <FlexGrid
    height="calc(100vh - 370px)"
    alignItems="center"
    justifyContent="center"
  >
    <FlexGridItem
      overrides={{
        Block: {
          style: () => ({
            flexGrow: 0,
            textAlign: 'center',
            width: 'auto',
          }),
        },
      }}
    >
      <CustomSpinner size="large" />
    </FlexGridItem>
  </FlexGrid>
)

export default Loading
