import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { Input, StatefulInput } from 'baseui/input'
import { FormControl } from 'baseui/form-control'
import { StatefulTooltip } from 'baseui/tooltip'

import { ERROR_MESSAGE } from '../../../constants'

const RMANumber = ({ isControlled }: { isControlled: boolean }) => {
  const {
    resetField,
    formState: { errors },
  } = useFormContext()

  React.useEffect(() => {
    if (!isControlled) {
      resetField('rma_number', { defaultValue: '' })
    }
  }, [isControlled, resetField])

  return (
    <FormControl
      label={() => (
        <>
          RMA Number{' '}
          <StatefulTooltip
            content={() => 'Return Merchandise Authorization'}
            returnFocus
            autoFocus
          >
            &#9432;
          </StatefulTooltip>
        </>
      )}
      error={errors?.rma_number?.message || null}
    >
      {isControlled ? (
        <Controller
          name="rma_number"
          rules={{
            required: ERROR_MESSAGE.required,
          }}
          render={({ field: { ref, ...fieldProps } }) => (
            <Input {...fieldProps} type="text" />
          )}
        />
      ) : (
        <StatefulInput type="text" disabled />
      )}
    </FormControl>
  )
}

export default RMANumber
