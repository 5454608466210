import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { Select, StatefulSelect } from 'baseui/select';
import { FormControl } from 'baseui/form-control';
import { ERROR_MESSAGE } from '../../../constants';

const defaultFieldProps = {
  placeholder: 'Select...',
  options: {
    __ungrouped: [],
    '<General>': [
      {
        id: 'ordered_wrong_size',
        label: 'Ordered Wrong Size',
      },
      {
        id: 'product_no_longer_needed',
        label: 'Product No Longer Needed',
      },
      {
        id: 'ordered_too_many',
        label: 'Ordered Too Many',
      },
      {
        id: 'wrong_product_ordered',
        label: 'Wrong Product Ordered',
      },
      {
        id: 'found_cheaper_elsewhere',
        label: 'Found Cheaper Elsewhere',
      },
      {
        id: 'deliver_timeframe_too_long',
        label: 'Delivery Timeframe Too Long',
      },
      {
        id: 'incorrect_product_description_or_image',
        label: 'Incorrect Product Description or Image',
      },
      { id: 'poor_quality', label: 'Poor Quality' },
    ],
    '<Happiness Policy>': [
      { id: 'hp_delivery_timeframe_too_long_dnr',
        label: 'Delivery Timeframe Too Long DNR'
      },
      { id: 'hp_incorrect_product_description_or_image_dnr',
        label: 'Incorrect Product Description or image DNR'
      },
      { id: 'hp_poor_quality_dnr',
        label: 'Poor Quality DNR'
      },
      { id: 'hp_patient_deceased_or_moved_to_hospice_dnr',
        label: 'Patient Deceased or Moved to Hospice DNR'
      },
      { id: 'hp_received_wrong_item_dnr',
        label: 'Received Wrong Item DNR'
      },
      { id: 'hp_autoship_not_cancelled_dnr',
        label: 'Autoship not Cancelled DNR'
      },
      { id: 'hp_did_not_receive_the_product_dnr',
        label: 'Did Not Receive the Product DNR'
      },
      { id: 'hp_item_damaged_dnr',
        label: 'Item Damaged DNR'
      },
      { id: 'hp_other_dnr',
        label: 'Other DNR'
      },
    ],
  },
};

const ReturnReasonCode = ({ namespace, index }: { namespace: string; index: number }) => {
  const {
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const FIELD_NAME = `${namespace}.${index}.reason_code`;

  const watchQuantity = watch(`${namespace}.${index}.return_quantity`);

  React.useEffect(() => {
    if (!watchQuantity || parseInt(watchQuantity) < 1) {
      clearErrors(FIELD_NAME);
    }
  }, [watchQuantity, FIELD_NAME, clearErrors]);

  return (
    <>
      <FormControl error={errors[namespace]?.[index]?.reason_code?.message || null}>
        {watchQuantity && parseInt(watchQuantity) > 0 ? (
          <Controller
            name={FIELD_NAME}
            rules={{ required: ERROR_MESSAGE.required }}
            render={({ field: { ref, ...fieldProps } }) => (
              <Select
                {...defaultFieldProps}
                {...fieldProps}
                onChange={({ value }: any) => fieldProps.onChange(value)}
              />
            )}
          />
        ) : (
          <StatefulSelect {...defaultFieldProps} labelKey="label" valueKey="id" />
        )}
      </FormControl>
    </>
  );
};

export default ReturnReasonCode;
