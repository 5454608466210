import * as React from 'react'
import { Grid, Cell, ALIGNMENT as GridAlignment } from 'baseui/layout-grid'
import { H1, H4 } from 'baseui/typography'

type PageHeaderProps = {
  heading: string
  subheading?: string
}

const PageHeader = (props: PageHeaderProps) => {
  return (
    <>
      <Grid>
        <Cell span={6}>
          <H1>{props.heading}</H1>
        </Cell>

        {props.subheading ? (
          <Cell
            span={6}
            align={GridAlignment.center}
            overrides={{
              Cell: {
                style: {
                  textAlign: 'right',
                },
              },
            }}
          >
            <H4 as='p' color='mono700'>
              {props.subheading}
            </H4>
          </Cell>
        ) : null}
      </Grid>
    </>
  )
}

export default PageHeader
