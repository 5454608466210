import * as React from 'react';
import { LettersProvider } from '../../context/letters-context';

import PageHeader from '../../common/layout/PageHeader';
import CustomerLookup from './forms/CustomerLookup';
import SendMail from './forms/SendMail';
import Confirmation from './forms/Confirmation';

import style from './SnailMail.module.css';
import snailIcon from '../../assets/img/snail.svg';
import { Notification, KIND } from 'baseui/notification';
import { FlexGrid } from 'baseui/flex-grid';

enum VIEWS {
  'LOOKUP',
  'FORM',
  'CONFIRMATION',
}
const errorMessage = `There was an error sending the physical mail. Please check your input and try again.`;

const SnailMail = () => {
  const [step, setStep] = React.useState(VIEWS.LOOKUP);
  const [error, setError] = React.useState<string | undefined>();

  const handleCustomerLookup = () => {
    setStep(VIEWS.FORM);
  };

  const handleSendMail = () => {
    setStep(VIEWS.CONFIRMATION);
  };

  const resetForm = () => {
    setStep(VIEWS.LOOKUP);
  };

  const handleError = (message?: string) => {
    setError(message ?? errorMessage);

    setTimeout(() => {
      setError(undefined);
    }, 10000);
  };

  const PageContent = () => {
    switch (step) {
      case VIEWS.LOOKUP:
        return <CustomerLookup onSubmit={handleCustomerLookup} />;
      case VIEWS.FORM:
        return <SendMail onCancel={resetForm} onSubmit={handleSendMail} onError={handleError} />;
      case VIEWS.CONFIRMATION:
        return <Confirmation onClick={resetForm} />;
    }
  };

  return (
    <LettersProvider>
      <PageHeader heading="Snail Mail" />

      {error && (
        <FlexGrid alignItems="center" justifyContent="center">
          <Notification kind={KIND.negative}>{error}</Notification>
        </FlexGrid>
      )}

      <PageContent />

      <div className={style.snail}>
        <img src={snailIcon} alt="" />
      </div>
    </LettersProvider>
  );
};

export default SnailMail;
