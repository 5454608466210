import * as React from 'react'
import { KIND as ButtonKind } from 'baseui/button'
import {
  Modal,
  FocusOnce,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE as ModalSize,
  ROLE as ModalRole,
} from 'baseui/modal'

type CancelConfirmationProps = {
  open: boolean
  setOpen: (status: boolean) => void
  onSubmit: () => void
}

const CancelConfirmation = (props: CancelConfirmationProps) => (
  <Modal
    onClose={() => props.setOpen(false)}
    closeable
    isOpen={props.open}
    animate
    autoFocus
    size={ModalSize.default}
    role={ModalRole.dialog}
  >
    <FocusOnce>
      <ModalHeader>Confirm</ModalHeader>
    </FocusOnce>
    <ModalBody>Are you sure you want to cancel this return request? You cannot undo this action.</ModalBody>
    <ModalFooter>
      <ModalButton kind={ButtonKind.tertiary} onClick={() => props.setOpen(false)}>
        No
      </ModalButton>
      <ModalButton onClick={props.onSubmit}>Yes</ModalButton>
    </ModalFooter>
  </Modal>
)

export default CancelConfirmation
