import React from 'react'
import { Grid, Cell } from 'baseui/layout-grid'
import { H1 } from 'baseui/typography'
import Retool from 'react-retool'
import style from './Autoship.module.css'

const AutoshipLookupBySku = () => {
  return (
    <>
      <Grid>
        <Cell span={12}>
          <H1>Autoship Lookup by Sku</H1>
          <div className={style.embed}>
            <Retool url={process.env.REACT_APP_RETOOL_AUTOSHIPS_BY_SKU} />
          </div>
        </Cell>
      </Grid>
    </>
  )
}

export default AutoshipLookupBySku
