import * as React from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Notification, KIND } from 'baseui/notification'

export interface ErrorProps {
  message: string
}

const Error = (props: ErrorProps) => (
  <FlexGrid height='calc(100vh - 60px)' alignItems='center' justifyContent='center'>
    <FlexGridItem
      overrides={{
        Block: {
          style: () => ({
            flexGrow: 0,
            textAlign: 'center',
            width: 'auto',
          }),
        },
      }}
    >
      <Notification kind={KIND.negative}>{props.message}</Notification>
    </FlexGridItem>
  </FlexGrid>
)

export default Error
