import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { Select } from 'baseui/select'
import { FormControl } from 'baseui/form-control'

import { ERROR_MESSAGE } from '../../../constants'

const AuthorizationStatus = ({
  namespace,
  index,
}: {
  namespace: string
  index: number
}) => {
  const {
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <FormControl
        error={
          errors[namespace]?.[index]?.authorization_status?.message || null
        }
      >
        <Controller
          name={`${namespace}.${index}.authorization_status`}
          rules={{ required: ERROR_MESSAGE.required }}
          render={({ field: { ref, ...fieldProps } }) => (
            <Select
              {...fieldProps}
              placeholder="Select..."
              onChange={({ value }) => fieldProps.onChange(value)}
              options={[
                { id: 'authorized', label: 'Authorized' },
                { id: 'not_authorized', label: 'Not Authorized' },
              ]}
            />
          )}
        />
      </FormControl>
    </>
  )
}

export default AuthorizationStatus
