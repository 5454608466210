import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { Input } from 'baseui/input'
import { FormControl } from 'baseui/form-control'

import { ERROR_MESSAGE } from '../../../constants'

const VendorCSR = () => {
  const {
    formState: { errors },
  } = useFormContext()

  return (
    <FormControl label="Your Email" error={errors?.vendor_csr?.message || null}>
      <Controller
        name="vendor_csr"
        rules={{
          required: ERROR_MESSAGE.required,
        }}
        render={({ field: { ref, ...fieldProps } }) => (
          <Input {...fieldProps} type="text" />
        )}
      />
    </FormControl>
  )
}

export default VendorCSR
