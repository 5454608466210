import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { Select, StatefulSelect } from 'baseui/select';
import { FormControl } from 'baseui/form-control';
import { ERROR_MESSAGE } from '../../../constants';

const defaultFieldProps = {
  placeholder: 'Select...',
  options: [
    { id: 'store_credit', label: 'Store Credit' },
    {
      id: 'original_payment',
      label: 'Original Payment',
    },
    { id: 'check', label: 'Check' },
  ],
};

const ReturnRefundType = ({ namespace, index }: { namespace: string; index: number }) => {
  const {
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const FIELD_NAME = `${namespace}.${index}.refund_type`;

  const watchQuantity = watch(`${namespace}.${index}.return_quantity`);

  React.useEffect(() => {
    if (!watchQuantity || parseInt(watchQuantity) < 1) {
      clearErrors(FIELD_NAME);
    }
  }, [watchQuantity, FIELD_NAME, clearErrors]);

  return (
    <>
      <FormControl error={errors[namespace]?.[index]?.refund_type?.message || null}>
        {watchQuantity && parseInt(watchQuantity) > 0 ? (
          <Controller
            name={FIELD_NAME}
            rules={{ required: ERROR_MESSAGE.required }}
            render={({ field: { ref, ...fieldProps } }) => (
              <Select
                {...defaultFieldProps}
                {...fieldProps}
                onChange={({ value }: any) => fieldProps.onChange(value)}
              />
            )}
          />
        ) : (
          <StatefulSelect {...defaultFieldProps} labelKey="label" valueKey="id" />
        )}
      </FormControl>
    </>
  );
};

export default ReturnRefundType;
