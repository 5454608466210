import * as React from 'react'
import { Block } from 'baseui/block'
import { Grid, Cell, ALIGNMENT as GridAlignment } from 'baseui/layout-grid'
import { StyledLink } from 'baseui/link'

import style from './Footer.module.css'
import logo from '../../assets/img/carewell-logo.svg'

const Footer = () => (
  <Block backgroundColor='mono200' paddingTop='40px' paddingBottom='50px'>
    <Grid align={GridAlignment.center}>
      <Cell span={4}>
        <img className={style.logo} src={logo} alt='Carewell, caregiving starts here' />
        <p style={{ lineHeight: '1.5em' }}>
          Our purpose is to help caregivers provide better care while making their lives easier.
        </p>
      </Cell>

      <Cell span={3}></Cell>

      <Cell span={3}>
        <p>Resources</p>
        <ul className={style.list}>
          <li className={style.listItem}>
            <StyledLink
              href='https://sites.google.com/care.club/customer-service/home#h.p_FY-ckCnfcjm1'
              target='_blank'
            >
              Keys of Care
            </StyledLink>
          </li>
          <li className={style.listItem}>
            <StyledLink
              href='https://sites.google.com/care.club/customer-service/home#h.p_4sGRsHqKCzew'
              target='_blank'
            >
              Distribution Centers
            </StyledLink>
          </li>
          <li className={style.listItem}>
            <StyledLink
              href='https://sites.google.com/care.club/customer-service/home#h.p_4sGRsHqKCzew'
              target='_blank'
            >
              UPS Coverage
            </StyledLink>
          </li>
        </ul>
      </Cell>

      <Cell span={2}>
        <p>Other Links</p>
        <ul className={style.list}>
          <li className={style.listItem}>
            <StyledLink href='https://app.heypoplar.com/users/sign_in' target='_blank'>
              Poplar
            </StyledLink>
          </li>
          <li className={style.listItem}>
            <StyledLink href='https://dashboard.lob.com/#/login' target='_blank'>
              LOB
            </StyledLink>
          </li>
          <li className={style.listItem}>
            <StyledLink href='https://postmarkapp.com' target='_blank'>
              Postmark
            </StyledLink>
          </li>
        </ul>
      </Cell>
    </Grid>
  </Block>
)

export default Footer
