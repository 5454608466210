import * as React from 'react'
import { Grid, Cell } from 'baseui/layout-grid'
import { Block } from 'baseui/block'
import { Button, KIND as ButtonKind } from 'baseui/button'
import { Notification, KIND as NotificationKind } from 'baseui/notification'

type ConfirmationProps = {
  onClick: () => void
}

const Confirmation = (props: ConfirmationProps) => {
  return (
    <Grid>
      <Cell span={12}>
        <Notification
          kind={NotificationKind.positive}
          overrides={{
            Body: { style: { width: 'auto' } },
            InnerContainer: {
              style: {
                width: '100%',
              },
            },
          }}
        >
          <span>Success! We’ve received your request to send mail</span>
        </Notification>
      </Cell>
      <Cell span={1}>
        <Block marginTop='44px'></Block>
        <Button
          type='button'
          kind={ButtonKind.secondary}
          onClick={props.onClick}
        >
          Done
        </Button>
      </Cell>
    </Grid>
  )
}

export default Confirmation
