import { useState } from 'react';
import { Notification, KIND as NotificationKind } from 'baseui/notification';
import type { ReturnLabelT, ReturnLabelTrackingT } from '../types';
import { useFormContext, Controller } from 'react-hook-form';
// import { ERROR_MESSAGE } from '../../../constants';
import { Input } from 'baseui/input';
import { FormControl } from 'baseui/form-control';
import { Button } from 'baseui/button';

const ReturnLabelNumber = ({
  file,
  tracking,
  onRemoveNumber,
  modifyNumber,
}: {
  file: ReturnLabelT;
  tracking: ReturnLabelTrackingT;
  onRemoveNumber: (file: ReturnLabelT, number: string) => void;
  modifyNumber: (file: ReturnLabelT, originalNumber: string, number: string) => void;
}) => {
  const {
    formState: { errors },
  } = useFormContext();
  const [value, setValue] = useState(tracking.number);

  return (
    <Notification
      kind={tracking.error ? NotificationKind.negative : NotificationKind.warning}
      overrides={{
        Body: { style: { width: 'auto' } },
      }}
      onClose={() => onRemoveNumber(file, tracking.number)}
      closeable
    >
      <FormControl
        error={errors?.return_label?.message || null}
        label={tracking.error ? 'Tracking number may not be valid, please verify' : tracking.carrier}
      >
        <Controller
          name={`${file.file}-tracking`}
          // Even if tracking number is potentially invalid, don't invalidate form
          // rules={{
          //   required: ERROR_MESSAGE.required,
          // }}
          render={({ field }) => (
            <Input
              {...field}
              type="text"
              value={value}
              onChange={e => setValue((e.target as HTMLInputElement).value)}
            />
          )}
        />
      </FormControl>
      <Button disabled={value === tracking.number} onClick={e => modifyNumber(file, tracking.number, value)}>
        Update
      </Button>
    </Notification>
  );
};

export default ReturnLabelNumber;
