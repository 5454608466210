import * as React from 'react'
import { Button } from 'baseui/button'

import CustomSpinner from './CustomSpinner'

const SubmitButton = ({
  isFull = false,
  isLoading,
  children,
  ...rest
}: any) => {
  return (
    <Button
      {...rest}
      type="submit"
      disabled={isLoading}
      overrides={{
        BaseButton: { style: { width: isFull ? '100%' : 'auto' } },
      }}
    >
      {isLoading ? (
        <>
          <CustomSpinner size="small" />
        </>
      ) : (
        children
      )}
    </Button>
  )
}

export default SubmitButton
