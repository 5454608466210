import * as React from 'react'
import { LettersContextT, DataT, FormDataT, ErrorsT } from './types'
import { ADDRESS_TYPES } from '../../constants'

export * from './types'

export const defaultData = {
  customerEmail: '',
  shippingAddress: undefined,
  billingAddress: undefined,
}

export const defaultFormData = {
  orderId: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  addressType: ADDRESS_TYPES[0],
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: [],
  zip: '',
}

const LettersContext = React.createContext<LettersContextT | undefined>(undefined)

export const LettersProvider = (props: any) => {
  const [data, setData] = React.useState<DataT>(defaultData)
  const [formData, setFormData] = React.useState<FormDataT>(defaultFormData)
  const [errors, setErrors] = React.useState<ErrorsT>({})

  return (
    <LettersContext.Provider
      value={{
        data,
        setData,
        formData,
        setFormData,
        errors,
        setErrors,
      }}
    >
      {props?.children}
    </LettersContext.Provider>
  )
}

export const useLetters = () => {
  const context = React.useContext(LettersContext)

  if (context === undefined) {
    throw new Error(`useLetters must be used within a LettersContext`)
  }

  return context
}
