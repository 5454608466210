import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { FileUploader } from 'baseui/file-uploader';
import { FormControl } from 'baseui/form-control';

import { ERROR_MESSAGE } from '../../../constants';
import { verifyTrackingNumbers } from '../../../utils';

type filesWithBarcodesType = File & {
  barcodes?: Record<string, any>;
};

const ReturnLabelUploader = ({ returnId, isControlled }: { returnId: string; isControlled: boolean }) => {
  const {
    setError,
    clearErrors,
    resetField,
    formState: { errors },
  } = useFormContext();
  const [isUploading, setIsUploading] = React.useState(false);
  const [abortController, setAbortController] = React.useState(new AbortController());

  React.useEffect(() => {
    if (!isControlled) {
      resetField('return_label', { defaultValue: [] });
    }
  }, [isControlled, resetField]);

  const cancelUpload = () => {
    abortController.abort();
    setIsUploading(false);
    setAbortController(new AbortController());
  };

  const caption = (
    <>
      <p>
        We attempt to extract the tracking numbers automatically and validate them with the carriers. After upload,
        please check and update (if needed) the tracking numbers from the labels or add additional/missing tracking
        numbers.
      </p>
      <p style={{ color: '#AB1300' }}>Tracking numbers in red weren't verifiable with the carrier.</p>
      <p style={{ color: '#BC8B2C' }}>Tracking numbers in yellow should be manually verified.</p>
    </>
  );

  return (
    <FormControl label="Return Label(s)" error={errors?.return_label?.message || null} caption={() => caption}>
      {isControlled ? (
        <Controller
          name="return_label"
          rules={{
            required: ERROR_MESSAGE.required,
          }}
          render={({ field: { ref, ...fieldProps } }) => (
            <FileUploader
              {...fieldProps}
              multiple
              accept=".pdf"
              onCancel={cancelUpload}
              onDropAccepted={async droppedFiles => {
                const acceptedFiles = droppedFiles as unknown as filesWithBarcodesType[];

                try {
                  setIsUploading(true);
                  const parseResults = await verifyTrackingNumbers(acceptedFiles, returnId, abortController);

                  if (parseResults) {
                    fieldProps.onChange([...(fieldProps.value || []), ...parseResults]);
                    clearErrors('return_label');
                    setIsUploading(false);
                  } else {
                    setError('return_label', { message: 'Could not parse tracking labels' });
                  }
                } catch (error) {
                  console.log('error parsing return labels', error);
                  // TODO: if failed due to aftership, notify user and cancel, allow them to retry

                  if ((error as Error).name !== 'AbortError') {
                    setError('return_label', { message: ERROR_MESSAGE.generic });
                  }
                  cancelUpload();
                }
              }}
              progressMessage={isUploading ? `Uploading and verifying...` : ''}
            />
          )}
        />
      ) : (
        <FileUploader disabled accept={'pdf'} />
      )}
    </FormControl>
  );
};

export default ReturnLabelUploader;
