export { verifyTrackingNumbers } from './parseBarcode';

export const parseJson = async (response: any) => {
  const text = await response.text();
  try {
    const json = JSON.parse(text);
    return json;
  } catch (error) {
    return null;
  }
};
