import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Grid, Cell } from 'baseui/layout-grid'
import { Block } from 'baseui/block'

import type { ReturnDataT } from '../types'
import ReturnAuthorizationTable from './ReturnAuthorizationTable'
import SubmitButton from '../../../common/generic/SubmitButton'
import ReturnLabelUploader from './ReturnLabelUploader'
import ReturnLabels from './ReturnLabels'
import RMANumber from './RMANumber'
import VendorCSR from './VendorCSR'

const ReturnAuthorizationFormBody = ({ data }: { data: ReturnDataT }) => {
  const {
    watch,
    getValues,
    register,
    resetField,
    formState: { isSubmitting },
  } = useFormContext()

  const [hasAuthorized, setHasAuthorized] = React.useState(false)
  const [hasLabel, setHasLabel] = React.useState(false)

  React.useEffect(() => {
    const subscription = watch((data) => {
      if (data.line_item) {
        const [totalAuthorized, totalReturns]: any = Object.values(
          data.line_item
        ).reduce(
          (totals: number[], lineItem: any) => {
            const isAuthorized =
              lineItem.authorization_status?.[0]?.id === 'authorized'
            const isReturn =
              lineItem.authorization_action?.[0]?.id === 'label_provided'
            return [
              totals[0] + (isAuthorized ? 1 : 0),
              totals[1] + (isReturn ? 1 : 0),
            ]
          },
          [0, 0]
        )

        setHasLabel(totalReturns > 0)
        setHasAuthorized(totalAuthorized > 0)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, getValues, register, resetField])

  return (
    <>
      <Grid>
        <Cell span={12}>
          <ReturnAuthorizationTable lineItems={data.line_items} />
        </Cell>

        <Cell span={12}>
          <Block marginTop="16px">&nbsp;</Block>
        </Cell>

        <Cell span={5}>
          <ReturnLabelUploader
            returnId={data.return_id}
            isControlled={hasLabel}
          />

          <ReturnLabels />
        </Cell>

        <Cell span={12}>
          <Block>&nbsp;</Block>
        </Cell>

        <Cell span={3}>
          <RMANumber isControlled={hasAuthorized} />
        </Cell>

        <Cell span={12}>
          <Block>&nbsp;</Block>
        </Cell>

        <Cell span={3}>
          <VendorCSR />
        </Cell>

        <Cell span={12}>
          <Block>&nbsp;</Block>
        </Cell>

        <Cell span={2}>
          <SubmitButton isFull={true} isLoading={isSubmitting}>
            Submit
          </SubmitButton>
        </Cell>
      </Grid>
    </>
  )
}

export default ReturnAuthorizationFormBody
