import React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { Notification, KIND as NotificationKind } from 'baseui/notification';
import { StyledLink } from 'baseui/link';

const ReturnRequestConfirmation = ({ orderNumber, airtableUrl }: { orderNumber: string; airtableUrl: string }) => {
  return (
    <>
      <Grid>
        <Cell span={12}>
          <Notification
            kind={NotificationKind.positive}
            overrides={{
              Body: { style: { width: 'auto' } },
              InnerContainer: {
                style: {
                  width: '100%',
                },
              },
            }}
          >
            <span>Success! We’ve received your return request for order number: {orderNumber}</span>
            {airtableUrl ? (
              <span style={{ float: 'right' }}>
                <StyledLink href={airtableUrl} target="_blank">
                  View in Airtable
                </StyledLink>
              </span>
            ) : null}
          </Notification>
        </Cell>
      </Grid>
    </>
  );
};

export default ReturnRequestConfirmation;
