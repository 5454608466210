import React from 'react'
import { Grid, Cell } from 'baseui/layout-grid'
import { Notification, KIND as NotificationKind } from 'baseui/notification'

const ReturnAuthorizationError = ({
  errorMessage,
}: {
  errorMessage?: string
}) => {
  if (!errorMessage) return null

  return (
    <>
      <Grid>
        <Cell span={12}>
          <Notification
            kind={NotificationKind.negative}
            overrides={{
              Body: { style: { width: 'auto', marginBottom: '32px' } },
              InnerContainer: {
                style: {
                  width: '100%',
                },
              },
            }}
          >
            <span>{errorMessage}</span>
          </Notification>
        </Cell>
      </Grid>
    </>
  )
}

export default ReturnAuthorizationError
