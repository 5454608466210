import React from 'react';
import { Block } from 'baseui/block';

import PageHeader from '../../common/layout/PageHeader';
import SearchReturnPOFormBody from './SearchReturnPOForm';
import ReturnAuthorizationForm from './ReturnAuthorizationForm';
import ReturnAuthorizationConfirmation from './ReturnAuthorizationConfirmation';
import ReturnAuthorizationCanceledAlert from './ReturnAuthorizationCanceledAlert';

import type { ReturnDataT } from './types';

const ReturnAuthorization = () => {
  const [returnData, setReturnData] = React.useState<ReturnDataT | null>(null);
  const [returnId, setReturnId] = React.useState('');

  const handleSearchSubmit = async (data: any) => {
    setReturnData(data);
    setReturnId('');
  };

  const handleReturnSubmit = async (data: any) => {
    setReturnId(data.returnId);
    setReturnData(null);
  };

  return (
    <>
      <PageHeader heading="Return Authorization" />

      {returnId ? <ReturnAuthorizationConfirmation returnId={returnId} /> : null}

      <SearchReturnPOFormBody returnId={returnId} onSearchSubmit={handleSearchSubmit} />

      {returnData ? (
        !returnData.rr_canceled ? (
          <>
            <Block marginTop="32px">&nbsp;</Block>
            <ReturnAuthorizationForm returnData={returnData} onReturnSubmit={handleReturnSubmit} />
          </>
        ) : (
          <ReturnAuthorizationCanceledAlert />
        )
      ) : null}
    </>
  );
};

export default ReturnAuthorization;
