import * as React from 'react';
import { UserT } from '../../types';
import { AuthContextT, StatusT } from './types';
import { CodeResponse, googleLogout, useGoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';

export * from './types';

const AuthContext = React.createContext<Partial<AuthContextT>>({});

export const AuthProvider = (props: any) => {
  const [status, setStatus] = React.useState<StatusT | undefined>();
  const [user, setUser] = React.useState<UserT | undefined>();
  const [error, setError] = React.useState<Error | undefined>();

  const getToken = React.useCallback(async (type: string, body: string) => {
    let userResult: UserT | undefined;

    const url = `${process.env.REACT_APP_CW_API_ADMIN_URL}/auth/token/${type}`;
    try {
      const res = await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body });
      const auth = await res.json();
      userResult = { ...auth.user, auth: auth.token };
    } catch (err) {
      userResult = undefined;
    }
    setUser(userResult);
    userResult ? Cookies.set('gUser', JSON.stringify(userResult)) : Cookies.remove('gUser');
    userResult ? setStatus('success') : handleError(new Error(`Error getting ${type} token`));
    return userResult;
  }, []);

  const checkAuth = React.useCallback(async () => {
    const storedUser = Cookies.get('gUser');
    if (!storedUser) {
      signOut();
    } else {
      const gUser = JSON.parse(storedUser) as UserT;
      // refresh before expiry
      if (gUser?.auth.expiryDate && gUser.auth.expiryDate <= new Date().getTime() - 5) {
        return await getToken('refresh', JSON.stringify({ refreshToken: gUser.auth.refreshToken }));
      } else {
        setUser(gUser);
        return gUser;
      }
    }
  }, [getToken]);

  React.useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const handleError = (error: Error) => {
    setStatus('error');
    setError(error);
  };

  const signOut = () => {
    googleLogout();
    Cookies.remove('gUser');
    setStatus('success');
    setUser(undefined);
  };

  const signIn = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: ({ code }: CodeResponse) => {
      if (code) {
        getToken('', JSON.stringify({ code }));
      }
    },
    onError: () => handleError(new Error('Error: Unable to login, please try again.')),
  });

  return (
    <AuthContext.Provider
      value={{
        error,
        status,
        user,
        signIn,
        signOut,
        checkAuth,
      }}
    >
      {props?.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
};
