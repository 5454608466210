import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { Select } from 'baseui/select'
import { FormControl } from 'baseui/form-control'

import { ERROR_MESSAGE } from '../../../constants'

const AuthorizationAction = ({
  namespace,
  index,
}: {
  namespace: string
  index: number
}) => {
  const {
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <FormControl
        error={
          errors[namespace]?.[index]?.authorization_action?.message || null
        }
      >
        <Controller
          name={`${namespace}.${index}.authorization_action`}
          rules={{ required: ERROR_MESSAGE.required }}
          render={({ field: { ref, ...fieldProps } }) => (
            <Select
              {...fieldProps}
              placeholder="Select..."
              onChange={({ value }) => fieldProps.onChange(value)}
              options={[
                { id: 'label_provided', label: 'Label Provided' },
                {
                  id: 'do_not_return',
                  label: 'Do Not Return',
                },
              ]}
            />
          )}
        />
      </FormControl>
    </>
  )
}

export default AuthorizationAction
