import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Grid, Cell } from 'baseui/layout-grid';
import { FormControl } from 'baseui/form-control';
import { ButtonGroup } from 'baseui/button-group';
import { Input, SIZE as InputSize } from 'baseui/input';
import { Search } from 'baseui/icon';

import { ERROR_MESSAGE } from '../../constants';
import SubmitButton from '../../common/generic/SubmitButton';

const SearchOrderFormBody = () => {
  const {
    formState: { isSubmitting, errors },
  } = useFormContext();

  return (
    <>
      <Grid>
        <Cell span={4}>
          <FormControl label="Order Number" error={errors.order_number?.message || null}>
            <ButtonGroup>
              <Controller
                name="order_number"
                rules={{ required: ERROR_MESSAGE.required }}
                render={({ field: { ref, ...fieldProps } }) => (
                  <Input
                    {...fieldProps}
                    startEnhancer={<Search size="26px" />}
                    placeholder="Search by order number..."
                    size={InputSize.large}
                  />
                )}
              />

              <SubmitButton isLoading={isSubmitting}>Search</SubmitButton>
            </ButtonGroup>
          </FormControl>
        </Cell>
      </Grid>
    </>
  );
};

export default SearchOrderFormBody;
