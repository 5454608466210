import React from 'react';
import { Block } from 'baseui/block';

import PageHeader from '../../common/layout/PageHeader';
import SearchOrderForm from './SearchOrderForm';
import ReturnRequestForm from './ReturnRequestForm';
import ReturnRequestConfirmation from './ReturnRequestConfirmation';
import type { OrderDataT, ReturnRequestT } from './types';

const ReturnRequest = () => {
  const [orderData, setOrderData] = React.useState<OrderDataT | null>(null);
  const [returnOrderNumber, setReturnOrderNumber] = React.useState('');
  const [airtableUrl, setAirtableUrl] = React.useState('');

  const handleSearchSubmit = async (data: OrderDataT) => {
    setOrderData(data);
    setReturnOrderNumber('');
    setAirtableUrl('');
  };

  const handleReturnSubmit = async (data: ReturnRequestT) => {
    setReturnOrderNumber(data.order_number);
    setAirtableUrl(data.airtable_url);
    setOrderData(null);
  };

  return (
    <>
      <PageHeader heading="Return Request" />

      {returnOrderNumber ? (
        <ReturnRequestConfirmation orderNumber={returnOrderNumber} airtableUrl={airtableUrl} />
      ) : null}

      <SearchOrderForm returnOrderNumber={returnOrderNumber} onSearchSubmit={handleSearchSubmit} />

      {orderData ? (
        <>
          <Block marginTop="32px">&nbsp;</Block>
          <ReturnRequestForm orderData={orderData} onReturnSubmit={handleReturnSubmit} />
        </>
      ) : null}
    </>
  );
};

export default ReturnRequest;
