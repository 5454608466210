import React from 'react'
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic'

import AuthorizationStatus from './AuthorizationStatus'
import AuthorizationAction from './AuthorizationAction'
import type { ReturnLineItemT } from '../types'

const INPUT_NAMESPACE = 'line_item'

const ReturnAuthorizationTable = ({
  lineItems,
}: {
  lineItems: Array<ReturnLineItemT>
}) => {
  return (
    <>
      <TableBuilder data={lineItems}>
        <TableBuilderColumn header="Vendor Id">
          {(row: ReturnLineItemT) => <p>{row.vendor_id}</p>}
        </TableBuilderColumn>

        <TableBuilderColumn header="Sku">
          {(row: ReturnLineItemT) => <p>{row.cw_sku}</p>}
        </TableBuilderColumn>

        <TableBuilderColumn header="Quantity">
          {(row: ReturnLineItemT) => <p>{row.return_quantity}</p>}
        </TableBuilderColumn>

        <TableBuilderColumn
          overrides={{
            TableHeadCell: { style: { width: '10%' } },
            TableBodyCell: { style: { width: '10%', textAlign: 'left' } },
          }}
          header="UOM"
          numeric
        >
          {(row: ReturnLineItemT) => <p>{row.vendor_uom}</p>}
        </TableBuilderColumn>

        <TableBuilderColumn
          overrides={{
            TableHeadCell: { style: { width: '30%' } },
            TableBodyCell: { style: { width: '30%' } },
          }}
          header="Status"
          numeric
        >
          {(row: ReturnLineItemT, index?: number) => (
            <AuthorizationStatus
              namespace={INPUT_NAMESPACE}
              index={index === undefined ? -1 : index}
            />
          )}
        </TableBuilderColumn>

        <TableBuilderColumn
          overrides={{
            TableHeadCell: { style: { width: '30%' } },
            TableBodyCell: { style: { width: '30%' } },
          }}
          header="Action"
          numeric
        >
          {(row: ReturnLineItemT, index?: number) => (
            <AuthorizationAction
              namespace={INPUT_NAMESPACE}
              index={index === undefined ? -1 : index}
            />
          )}
        </TableBuilderColumn>
      </TableBuilder>
    </>
  )
}

export default ReturnAuthorizationTable
