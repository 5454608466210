import { Value } from 'baseui/select';

import { OptionT } from './types';

// UOM: Unit of Measure
export const UOM = {
  case: 'CS',
  package: 'PK',
  bag: 'BG',
  box: 'BX',
  each: 'EA',
  roll: 'RL',
  pair: 'PR',
  can: 'CN',
  bottle: 'BT',
  carton: 'CT',
  dozen: 'DZ',
};

// Types of Customer Addresses
export const ADDRESS_TYPES: OptionT[] = [
  { label: 'Billing Address', id: 'billing' },
  { label: 'Shipping Address', id: 'shipping' },
];

// State Names and Abbreviations
export const STATES: Value = [
  { label: 'AK', id: 'Alaska' },
  { label: 'AL', id: 'Alabama' },
  { label: 'AR', id: 'Arkansas' },
  { label: 'AZ', id: 'Arizona' },
  { label: 'CA', id: 'California' },
  { label: 'CO', id: 'Colorado' },
  { label: 'CT', id: 'Connecticut' },
  { label: 'DC', id: 'District of Columbia' },
  { label: 'DE', id: 'Delaware' },
  { label: 'FL', id: 'Florida' },
  { label: 'GA', id: 'Georgia' },
  { label: 'HI', id: 'Hawaii' },
  { label: 'IA', id: 'Iowa' },
  { label: 'ID', id: 'Idaho' },
  { label: 'IL', id: 'Illinois' },
  { label: 'IN', id: 'Indiana' },
  { label: 'KS', id: 'Kansas' },
  { label: 'KY', id: 'Kentucky' },
  { label: 'LA', id: 'Louisiana' },
  { label: 'MA', id: 'Massachusetts' },
  { label: 'MD', id: 'Maryland' },
  { label: 'ME', id: 'Maine' },
  { label: 'MI', id: 'Michigan' },
  { label: 'MN', id: 'Minnesota' },
  { label: 'MO', id: 'Missouri' },
  { label: 'MS', id: 'Mississippi' },
  { label: 'MT', id: 'Montana' },
  { label: 'NC', id: 'North Carolina' },
  { label: 'ND', id: 'North Dakota' },
  { label: 'NE', id: 'Nebraska' },
  { label: 'NH', id: 'New Hampshire' },
  { label: 'NJ', id: 'New Jersey' },
  { label: 'NM', id: 'New Mexico' },
  { label: 'NV', id: 'Nevada' },
  { label: 'NY', id: 'New York' },
  { label: 'OH', id: 'Ohio' },
  { label: 'OK', id: 'Oklahoma' },
  { label: 'OR', id: 'Oregon' },
  { label: 'PA', id: 'Pennsylvania' },
  { label: 'RI', id: 'Rhode Island' },
  { label: 'SC', id: 'South Carolina' },
  { label: 'SD', id: 'South Dakota' },
  { label: 'TN', id: 'Tennessee' },
  { label: 'TX', id: 'Texas' },
  { label: 'UT', id: 'Utah' },
  { label: 'VA', id: 'Virginia' },
  { label: 'VT', id: 'Vermont' },
  { label: 'WA', id: 'Washington' },
  { label: 'WI', id: 'Wisconsin' },
  { label: 'WV', id: 'West Virginia' },
  { label: 'WY', id: 'Wyoming' },
];

export const ERROR_MESSAGE = {
  generic: 'Something went wrong, please try again',
  required: 'Required',
  min: (min: number) => `Must be ${min} or more`,
  max: (max: number) => `Must be ${max} or less`,
};
