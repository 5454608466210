import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { Grid, Cell } from 'baseui/layout-grid';
import { Select } from 'baseui/select';
import { FormControl } from 'baseui/form-control';
import { Block } from 'baseui/block';
import { SIZE as InputSize } from 'baseui/input';
import { Textarea } from 'baseui/textarea';

import type { OrderDataT } from '../types';
import ReturnRequestTable from './ReturnRequestTable';
import SubmitButton from '../../../common/generic/SubmitButton';

const ReturnRequestFormBody = ({ data }: { data: OrderDataT }) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <>
      <Grid>
        {/* Commenting - as far as I can tell, this was never hooked up */}
        {/* <Cell span={12}>
          <Controller
            name="return_full"
            render={({ field: { ref, ...fieldProps } }) => (
              <Checkbox
                {...fieldProps}
                labelPlacement={LABEL_PLACEMENT.right}
                checked={fieldProps.value}
              >
                Return Entire Order
              </Checkbox>
            )}
          />
        </Cell> */}

        <Cell span={12}>
          <Controller
            name="automation_disabled"
            render={({ field: { ref, ...fieldProps } }) => (
              <Checkbox {...fieldProps} labelPlacement={LABEL_PLACEMENT.right} checked={fieldProps.value}>
                Disable Refund Automation
              </Checkbox>
            )}
          />
        </Cell>

        <Cell span={12}>
          <Block marginTop="16px">&nbsp;</Block>
        </Cell>

        <Cell span={12}>
          <ReturnRequestTable lineItems={data.line_items} />
        </Cell>

        <Cell span={12}>
          <Block marginTop="16px">&nbsp;</Block>
        </Cell>

        <Cell span={12}>
          <p>Total Label Count by Vendor</p>
        </Cell>

        {data.label_details.map((detail, index) => (
          <Cell key={index} span={2}>
            <FormControl label={detail.name.charAt(0).toUpperCase() + detail.name.slice(1)}>
              <Controller
                name={`label_count.${index}`}
                render={({ field: { ref, ...fieldProps } }) => (
                  <Select
                    {...fieldProps}
                    placeholder="Select..."
                    onChange={({ value }) => fieldProps.onChange(value)}
                    options={Array.from(Array(detail.labelCount + 1)).map((_, index) => ({ id: index, label: index }))}
                  />
                )}
              />
            </FormControl>
          </Cell>
        ))}

        <Cell span={12}>
          <Block>&nbsp;</Block>
        </Cell>

        <Cell span={4}>
          <FormControl label={() => 'Notes'}>
            <Controller
              name={'cw_user_notes'}
              render={({ field: { ref, ...fieldProps } }) => (
                <Textarea
                  {...fieldProps}
                  onChange={event => {
                    fieldProps.onChange(event);
                  }}
                  size={InputSize.large}
                  maxLength={200}
                />
              )}
            />
          </FormControl>
        </Cell>

        <Cell span={12}>
          <Block>&nbsp;</Block>
        </Cell>

        <Cell span={2}>
          <SubmitButton isFull={true} isLoading={isSubmitting}>
            Submit Return
          </SubmitButton>
        </Cell>
      </Grid>
    </>
  );
};

export default ReturnRequestFormBody;
