import React from 'react';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';

import ReturnQuantityInput from './ReturnQuantityInput';
import ReturnReasonCode from './ReturnReasonCode';
import ReturnRefundType from './ReturnRefundType';
import type { OrderLineItemT } from '../types';

const INPUT_NAMESPACE = 'line_item';

const ReturnRequestTable = ({ lineItems }: { lineItems: Array<OrderLineItemT> }) => {
  return (
    <>
      <TableBuilder data={lineItems}>
        <TableBuilderColumn header="Product Name">{row => <p>{row.cw_product_name}</p>}</TableBuilderColumn>

        <TableBuilderColumn
          overrides={{
            TableHeadCell: { style: { width: '10%' } },
            TableBodyCell: { style: { width: '10%', textAlign: 'left' } },
          }}
          header="Sku"
          numeric
        >
          {(row: OrderLineItemT) => <p>{row.cw_sku}</p>}
        </TableBuilderColumn>

        <TableBuilderColumn
          overrides={{
            TableHeadCell: { style: { width: '5%' } },
            TableBodyCell: { style: { width: '5%', textAlign: 'left' } },
          }}
          header="Vendor"
          numeric
        >
          {(row: OrderLineItemT) => <p>{row.vendor_name}</p>}
        </TableBuilderColumn>

        <TableBuilderColumn
          overrides={{
            TableHeadCell: { style: { width: '12%' } },
            TableBodyCell: { style: { width: '12%' } },
          }}
          header="Qty"
          numeric
        >
          {(row: OrderLineItemT, index?: number) => (
            <ReturnQuantityInput
              namespace={INPUT_NAMESPACE}
              index={index === undefined ? -1 : index}
              maxQuantity={row.return_quantity}
            />
          )}
        </TableBuilderColumn>

        <TableBuilderColumn
          overrides={{
            TableHeadCell: { style: { width: '5%' } },
            TableBodyCell: { style: { width: '5%', textAlign: 'left' } },
          }}
          header="UOM"
          numeric
        >
          {(row: OrderLineItemT) => <p>{row.vendor_uom}</p>}
        </TableBuilderColumn>

        <TableBuilderColumn
          overrides={{
            TableHeadCell: { style: { width: '28%' } },
            TableBodyCell: { style: { width: '28%' } },
          }}
          header="Reason Code"
          numeric
        >
          {(row: OrderLineItemT, index?: number) => (
            <ReturnReasonCode namespace={INPUT_NAMESPACE} index={index === undefined ? -1 : index} />
          )}
        </TableBuilderColumn>

        <TableBuilderColumn
          overrides={{
            TableHeadCell: { style: { width: '15%' } },
            TableBodyCell: { style: { width: '15%' } },
          }}
          header="Refund Type"
          numeric
        >
          {(row: OrderLineItemT, index?: number) => (
            <ReturnRefundType namespace={INPUT_NAMESPACE} index={index === undefined ? -1 : index} />
          )}
        </TableBuilderColumn>
      </TableBuilder>
    </>
  );
};

export default ReturnRequestTable;
